import React, { useEffect, useState } from 'react';
import {
  Switch, Tooltip, Tabs, Tab,
} from '@mui/material';
import {
  DataGridPro,
} from '@mui/x-data-grid-pro';
import { useFetch } from './api';
import SyncConnectionModal from './SyncConnectionModal';
import DeleteObjectModal from './DeleteObjectModal';

function NoErrorsMessage() {
  return (
    <span className="flex justify-center align-center">No Errors</span>
  );
}

function ConnectionDetailDrawer({
  showConnectionDetail, connectionDetail, fetchConnectionData, closeDrawer,
}) {
  const trackstar = useFetch();
  const [errorRows, setErrorRows] = React.useState([]);
  const [scheduleRows, setScheduleRows] = React.useState([]);
  const [scheduleRowsLoading, setScheduleRowsLoading] = React.useState(true);
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [rowToSync, setRowToSync] = useState(null);
  const [resourceToSync, setResourceToSync] = useState(null);
  const [accessToken, setAccessToken] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeletingRow, setIsDeletingRow] = useState(false);
  const [logo, setLogo] = useState('ts_logo_2024.png');
  const [tabIndex, setTabIndex] = useState(0);

  const getConnectionSchedules = () => {
    setScheduleRowsLoading(true);
    trackstar(`internal/schedules/${connectionDetail.connection_id}`, 'GET').then((response) => {
      const newScheduleRows = response.data.map((schedule) => (
        { id: schedule.resource_name, ...schedule }));
      setScheduleRows(newScheduleRows);
      setScheduleRowsLoading(false);
    });
  };

  const getAccessToken = () => {
    trackstar(`internal/access-token?connection_id=${connectionDetail.connection_id}&integration_name=${connectionDetail.integration_name}`, 'GET').then((response) => {
      setAccessToken(response.access_token);
    });
  };

  const copyAccessToken = () => {
    navigator.clipboard.writeText(accessToken);
    alert('Access Token copied to clipboard');
  };

  const handleOpenSyncModal = (resource) => {
    setSyncModalOpen(true);
    setRowToSync(connectionDetail);
    setResourceToSync(resource);
  };

  const handleCloseSyncModal = (cancelled) => {
    if (!cancelled) {
      fetchConnectionData();
    }
    setSyncModalOpen(false);
    setRowToSync(null);
    setResourceToSync(null);
  };

  const handleSync = () => {
    trackstar(`internal/connections/sync/${resourceToSync.connection_id}`, 'POST', { functions_to_sync: [resourceToSync.function_name] }).then(() => {
      handleCloseSyncModal(false);
    });
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = (cancelled) => {
    if (!cancelled) {
      fetchConnectionData();
    }
    setDeleteModalOpen(false);
  };

  const handleDeleteConnection = () => {
    setIsDeletingRow(true);
    trackstar(`internal/connections/${connectionDetail.connection_id}`, 'DELETE').then(() => {
      handleCloseDeleteModal(false);
      setIsDeletingRow(false);
      closeDrawer();
    });
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const readableFrequency = (frequency) => {
    const frequencyNum = Number(frequency);
    const hours = Math.floor(frequencyNum / 3600);
    const minutes = Math.floor((frequencyNum % 3600) / 60);
    const hoursDisplay = hours > 0 ? `${hours}h ` : '';
    const minutesDisplay = minutes > 0 ? `${minutes}m` : '';
    return `${hoursDisplay}${minutesDisplay}`;
  };

  useEffect(() => {
    if (showConnectionDetail) {
      getConnectionSchedules();
      getAccessToken();
      setErrorRows(connectionDetail.errors.map((error, index) => ({ id: index, ...error })));
      if (connectionDetail !== 'sandbox') {
        setLogo(`https://trackstarlogosbucket.s3.amazonaws.com/${connectionDetail.integration_name}.png`);
      }
    }
  }, [showConnectionDetail]);

  return (
    <div className="flex flex-col p-4 space-y-3">
      <div className="flex justify-between">
        <div className="flex justify-self-center items-self-center items-center px-1 space-x-2">
          <Tooltip title={connectionDetail?.integration_name} arrow>
            <img src={logo} alt={`${connectionDetail?.integration_name} logo`} className="h-6 w-6" />
          </Tooltip>
          <h1 className="font-medium text-gray-600 overflow-hidden text-ellipsis max-w-lg">{connectionDetail?.connection_id}</h1>
        </div>
        <div className="flex space-x-1 justify-self-end items-center">
          <Tooltip title={connectionDetail.webhooks_disabled ? 'Enable Webhooks' : 'Disable Webhooks'} arrow>
            <Switch
              checked={!connectionDetail.webhooks_disabled}
              onChange={() => {
                trackstar(`internal/connections/${connectionDetail.connection_id}`, 'PATCH', { webhooks_disabled: !connectionDetail.webhooks_disabled }).then(() => {
                  fetchConnectionData();
                });
              }}
              className="-mr-2"
              sx={{
                '& .MuiSwitch-colorPrimary': {
                  '&.Mui-checked': {
                    color: '#8B5CF6',
                  },
                  '&.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#8B5CF6',
                  },
                },
                '& .MuiSwitch-thumb': {
                  backgroundImage: 'url(\'webhooks.png\')',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: '100%',
                },
                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked': {
                    '& .MuiSwitch-thumb': {
                      backgroundImage: 'url(\'webhooks-white.png\')',
                    },
                  },
                },
              }}
            />
          </Tooltip>
          <Tooltip title="Copy Access Token" arrow>
            <input type="image" alt="copy access token" src="key.png" id="access-token" className="h-6 w-6" onClick={copyAccessToken} />
          </Tooltip>
          <Tooltip title="Delete Connection" arrow>
            <input type="image" alt="delete connection" src="trash.png" className="h-6 w-6" onClick={handleOpenDeleteModal} />
          </Tooltip>
          <input type="image" alt="close drawer" src="close.png" className="h-6 w-6" onClick={closeDrawer} />
        </div>
      </div>
      <div className="flex space-x-10 items-center my-2">
        <div>
          <button className={`px-3 py-1 border rounded-lg ${connectionDetail?.errors.length > 0 ? 'border-trackstarBadDark text-trackstarBadDark' : 'border-trackstarOKDark text-trackstarOKDark'}`} type="button" onClick={() => handleTabChange(null, 1)}>{connectionDetail?.errors.length > 0 ? 'Errors' : 'OK'}</button>
        </div>
        <div className="items-center space-x-2 ">
          <span className="cardLabel ml-2">Created Date</span>
          <h1 className="font-medium text-sm ">{new Date(connectionDetail?.created_at).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' })}</h1>
        </div>
        <div className="items-center space-x-2">
          <span className="cardLabel ml-2">API Calls</span>
          <h1 className="font-medium text-sm ">{connectionDetail?.times_used || 0}</h1>
        </div>
        <div className="items-center space-x-2">
          <span className="cardLabel ml-2">Last Used</span>
          <h1 className="font-medium text-sm ">{connectionDetail?.last_used ? new Date(connectionDetail.last_used).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' }) : 'Never'}</h1>
        </div>
      </div>
      <div style={{ minHeight: '150px' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} sx={{ '& .MuiTabs-indicator': { backgroundColor: '#7F56D9' } }}>
          <Tab label="Sync Schedules" index={0} sx={{ textTransform: 'none', color: '#7F56D9', '&.Mui-selected': { color: '#7F56D9' } }} />
          <Tab label="Sync Errors" index={1} sx={{ textTransform: 'none', color: '#7F56D9', '&.Mui-selected': { color: '#7F56D9' } }} />
        </Tabs>
        {tabIndex === 0 && (
        <DataGridPro
          rows={scheduleRows}
          autoHeight
          loading={scheduleRowsLoading}
          columns={[
            {
              field: 'resource_name', headerName: 'Resource Name', flex: 2,
            },
            {
              field: 'last_crawl_end',
              headerName: 'Latest Sync',
              flex: 2,
              valueFormatter: (params) => {
                const { value } = params;
                if (!value) {
                  return 'No data';
                }
                const date = new Date(value);
                return date.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });
              },
            },
            {
              field: 'crawl_frequency',
              headerName: 'Sync Frequency',
              flex: 2,
              valueFormatter: (params) => {
                const { value } = params;
                return `Every ${readableFrequency(value)}`;
              },
            },
            {
              field: 'sync',
              headerName: 'Sync',
              flex: 1,
              renderCell: (params) => (
                <input type="image" alt="sync resource" src="regenerate_purple.png" className="h-6 w-6" onClick={() => handleOpenSyncModal(params.row)} />
              ),
            },
          ]}
          className="shadow-sm"
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#F5F5F5',
              borderRadius: '0px !important',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
              fontWeight: '400',
              fontSize: '13px',
              color: '#6b7280',
            },
            '& .column-header-no-border .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            borderRadius: '0px !important',
            '& .MuiDataGrid-cell': {
              fontSize: '13px',
              fontWeight: '400',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
            },
          }}
          hideFooter
        />
        )}
        {tabIndex === 1 && (
        <DataGridPro
          rows={errorRows}
          getRowHeight={() => 'auto'}
          autoHeight
          slots={{
            noRowsOverlay: NoErrorsMessage,
          }}
          columns={[
            {
              field: 'error_message', headerName: 'Error Message', width: 300,
            },
            {
              field: 'affected_endpoints',
              headerName: 'Affected Endpoints',
              width: 250,
              renderCell: (params) => {
                const { value } = params;
                return (<div>{value.map((endpoint) => <button className={'px-3 py-1 mx-1 my-1 border rounded-2xl \'border-trackstarOKDark text-trackstarOKDark\''} type="button" disabled>{endpoint}</button>)}</div>);
              },
            },
            {
              field: 'first_seen',
              headerName: 'First Seen',
              flex: 1,
              valueFormatter: (params) => {
                const { value } = params;
                const date = new Date(value);
                return date.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });
              },
            },
            {
              field: 'last_seen',
              headerName: 'Last Seen',
              flex: 1,
              valueFormatter: (params) => {
                const { value } = params;
                const date = new Date(value);
                return date.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });
              },

            },
          ]}
          className="shadow-sm"
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#F5F5F5',
              borderRadius: '0px !important',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
              fontWeight: '400',
              fontSize: '13px',
              color: '#6b7280',
            },
            '& .column-header-no-border .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            borderRadius: '0px !important',
            '& .MuiDataGrid-cell': {
              fontSize: '13px',
              fontWeight: '400',
              padding: '10px',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
            },
          }}
          hideFooter
        />
        )}
      </div>
      <SyncConnectionModal
        isOpen={syncModalOpen}
        onClose={handleCloseSyncModal}
        onSync={handleSync}
        isLoading={false}
        row={rowToSync}
        selectedResource={resourceToSync?.resource_name}
      />
      <DeleteObjectModal
        isOpen={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteConnection}
        isLoading={isDeletingRow}
        row={connectionDetail}
        objectType="Connection"
      />
    </div>
  );
}

export default ConnectionDetailDrawer;

import React, { useState, useEffect, useCallback } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import {
  DataGridPro, useGridApiRef,
} from '@mui/x-data-grid-pro';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Drawer from '@mui/material/Drawer';
import { useOrganization } from '@clerk/clerk-react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Tooltip } from 'react-tooltip';
import { TrackstarConnectButton } from '@trackstar/react-trackstar-link';
import { useFetch } from './api';
import HorizontalScrollText from './HorizontalScroll';
import CustomColumnMenu from './CustomColumnMenu';
import GenerateMagicLinkModal from './GenerateMagicLinkModal';
import ConnectionDetailDrawer from './ConnectionDetailDrawer';

LicenseInfo.setLicenseKey(
  'cfb90503f538324ac26942148034f3d4Tz05NzAwOSxFPTE3NTY0MDY1NjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
);

function Connections(props) {
  const { organization } = useOrganization();
  const trackstar = useFetch();
  const [selectedGridFilter, setSelectedGridFilter] = useState('All');
  const [adjustedConnectionData, setAdjustedConnectionData] = useState(null);
  const [connectionRows, setConnectionRows] = useState(null);
  const [quickFilterSearchValue, setQuickFilterSearchValue] = useState('');
  const [installedIntegrationResponse, setInstalledIntegrationResponse] = useState(null);
  const [generateMagicLinkModalOpen, setGenerateMagicLinkModalOpen] = useState(false);
  const [showEditCustomerIdConfirmation, setShowEditCustomerIdConfirmation] = useState(false);
  const [showConnectionDetail, setShowConnectionDetail] = useState(false);
  const [connectionDetail, setConnectionDetail] = useState(null);

  const gridApiRef = useGridApiRef();

  const {
    fetchConnectionData, connectionData, loadingConnectionData, connectionIssues,
  } = props;

  const handleEditCustomerId = (connectionId) => {
    const customerId = prompt('Enter a new nickname for this connection:');
    if (customerId) {
      trackstar(`internal/connections/${connectionId}`, 'PATCH', { customer_id: customerId }).then(() => {
        fetchConnectionData();
      });
      setShowEditCustomerIdConfirmation(true);
    }
  };

  const openConnectionDetail = (row) => {
    setConnectionDetail(row);
    setShowConnectionDetail(true);
  };

  const getTrackstarConnectButton = (buttonBody, styling, connectionId) => {
    const postBody = {};
    if (connectionId) {
      postBody.connection_id = connectionId;
    }
    return (
      <TrackstarConnectButton
        getLinkToken={async () => {
          const linkToken = await trackstar('internal/link/token', 'POST', postBody).then((response) => response.link_token);
          return linkToken;
        }}
        onSuccess={async (authCode) => {
          await trackstar('internal/link/exchange', 'POST', { auth_code: authCode }).then((response) => {
            fetchConnectionData();
            setInstalledIntegrationResponse(response);
            return response;
          });
        }}
        onClose={() => console.log('closed')}
        onLoad={() => console.log('loaded')}
        style={styling}
        className="hover:bg-trackstarBlueHover"
      >
        {buttonBody}
      </TrackstarConnectButton>
    );
  };

  const colHeaders = [
    {
      field: 'connection_id', headerName: 'Connection ID', width: 300,
    },
    {
      field: 'integration_name',
      headerName: 'Integration Name',
      width: 200,
      valueFormatter: (params) => {
        const { value } = params;
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      renderCell: (params) => {
        const { value } = params;
        let logo = `https://trackstarlogosbucket.s3.amazonaws.com/${value}.png`;
        if (value === 'sandbox') {
          logo = 'ts_logo_2024.png';
        }
        return (
          <div className="flex gap-2 items-center">
            <img src={logo} alt={`${value} logo`} className="h-5 w-5" />
            <p>{value.charAt(0).toUpperCase() + value.slice(1)}</p>
          </div>
        );
      },
    },
    {
      field: 'created_at',
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <div>Created Date</div>
          <img id="created-date-tip" src="info.png" alt="total connections" className="h-5 w-5" />
        </div>
      ),
      width: 200,
      valueFormatter: (params) => {
        const { value } = params;
        const date = new Date(value);
        return date.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });
      },
    },
    {
      field: 'times_used',
      headerName: 'API Calls',
      width: 100,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params) => {
        const { value } = params;
        return value || 0;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        const className = params.value === 'OK' ? 'border-trackstarOKDark text-trackstarOKDark' : 'border-trackstarBadDark text-trackstarBadDark';
        return <button className={`px-3 py-1 border rounded-lg ${className}`} type="button">{params.value}</button>;
      },
    },
    {
      field: 'customer_id',
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <div>Customer ID</div>
          <img id="customer-id-tip" src="info.png" alt="customer_id" className="h-5 w-5" />
        </div>
      ),
      renderCell: (params) => (
        <div className="flex w-full justify-end gap-2">
          <span>{params.value}</span>
          <button
            type="button"
            onClick={(event) => {
              handleEditCustomerId(params.row.connection_id);
              event.stopPropagation();
            }}
          >
            <img src="/edit.png" alt="edit" className="h-5 w-5" />
          </button>
        </div>
      ),
    },
  ];

  const updateSearchValue = (newValue) => setTimeout(() => {
    gridApiRef.current.setQuickFilterValues(
      newValue.split(' ').filter((word) => word !== ''),
    );
  }, 300);

  const handleQuickFilterSearchChange = (event) => {
    if (event.target.value !== undefined) {
      setQuickFilterSearchValue(event.target.value);
      updateSearchValue(event.target.value);
    }
  };

  useEffect(() => {
    if (organization) {
      fetchConnectionData();
    }
  }, [organization]);

  useEffect(() => {
    if (connectionData) {
      const adjustedData = [];
      connectionData.forEach((conn, index) => {
        const connection = conn;
        const status = conn.errors.length > 0 ? 'Errors' : 'OK';
        adjustedData.push({
          id: index, status, ...connection,
        });
      });
      setAdjustedConnectionData(adjustedData);
      setConnectionRows(adjustedData);
      // Update drawer data if open
      if (connectionDetail) {
        const newConnectionDetail = adjustedData.find(
          (conn) => conn.connection_id === connectionDetail.connection_id,
        );
        setConnectionDetail(newConnectionDetail);
      }
    }
  }, [connectionData]);

  useEffect(() => {
    const rows = [];
    if (!adjustedConnectionData) return;
    adjustedConnectionData.forEach((connection, index) => {
      const row = { id: index, ...connection };
      if (row.times_used === null) {
        row.times_used = 0;
      }
      // TODO: replace with actual values once connection-errors table is set up
      if (selectedGridFilter === 'OK' && row.status !== 'OK') {
        return;
      }
      if (selectedGridFilter === 'Errors' && row.status !== 'Errors') {
        return;
      }
      rows.push(row);
    });
    setConnectionRows(rows);
  }, [selectedGridFilter]);

  const getDetailPanelHeight = useCallback(() => 200, []);

  return (
    <div className="flex flex-col gap-8 p-5 w-10/12 h-screen">
      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold leading-9">
          Connections
        </h1>
        {
          showEditCustomerIdConfirmation && (
            <div className="flex items-center justify-center bg-green-100 px-4 py-3 rounded-lg">
              <p className="text-green-700 font-semibold">Customer ID successfully updated!</p>
            </div>
          )
        }
        <div className="flex gap-1">
          {getTrackstarConnectButton(
            (
              <div className="flex justify-between">
                <svg className="h-5 w-5 mr-2" focusable="false" aria-hidden="true">
                  <path fill="#2a6faf" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                </svg>
                Add new connection
              </div>
            ),
            {
              border: '1px solid #2a6faf',
              borderRadius: '8px',
              color: '#2a6faf',
              background: null,
            },
          )}
          <button
            type="button"
            style={{
              border: '1px solid #2a6faf', borderRadius: '8px', color: '#2a6faf', background: null, padding: '10px 20px',
            }}
            className="hover:bg-trackstarBlueHover"
            onClick={() => setGenerateMagicLinkModalOpen(true)}
          >
            <div className="flex justify-between">
              <AutoFixHighIcon className="h-5 w-5 mr-2" />
              Generate Magic Link
            </div>
          </button>
        </div>
      </div>
      {installedIntegrationResponse && (
        <div className="flex border border-trackstarBlueLight rounded-lg p-5 w-1/2 flex-col">
          <div className="flex gap-2 items-center justify-between p-2">
            <h2 className="font-medium text-gray-600"> Connection successfully installed! </h2>
            <button
              type="button"
              onClick={() => setInstalledIntegrationResponse(null)}
              className="px-3 py-1 border rounded-lg border-trackstarBlueLight text-trackstarBlueLight hover:bg-trackstarBlueHover"
            >
              Close
            </button>
          </div>
          <div className="flex gap-2 items-center p-2">
            <p className="font-bold"> ***Be sure to copy your access token below and store it somewhere safe*** </p>
          </div>
          <table className="table-auto">
            <tbody>
              <tr>
                <td className="border px-4 py-2">Integration Name</td>
                <td className="border px-4 py-2" label="Integration Name"><HorizontalScrollText content={installedIntegrationResponse.integration_name} /></td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Access Token</td>
                <td className="border px-4 py-2 max-w-sm" label="Access Token"><HorizontalScrollText content={installedIntegrationResponse.access_token} /></td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Connection ID</td>
                <td className="border px-4 py-2" label="Connection ID"><HorizontalScrollText content={installedIntegrationResponse.connection_id} /></td>
              </tr>
            </tbody>
          </table>

        </div>
      )}
      <div className="flex gap-10 w-full">
        <div className="shadow-sm rounded-lg p-5 border w-1/3 flex flex-col justify-between h-32">
          <div className="flex items-center gap-3">
            <img src="totalConnections.png" alt="total connections" className="h-10 w-10" />
            <h2 className="font-medium text-gray-600"> Total Connections</h2>
          </div>
          {loadingConnectionData ? <ClipLoader />
            : <p className="text-2xl font-bold pl-2">{adjustedConnectionData ? adjustedConnectionData.length : 0}</p>}
        </div>
        <div className="shadow-sm rounded-lg p-5 border w-1/3 h-32 flex flex-col justify-between">
          <div className="flex gap-3 items-center">
            <img src="connectionIssues.png" alt="connection issues" className="h-10 w-10" />
            <h2 className="font-medium text-gray-600"> Connection Issues</h2>
          </div>
          {loadingConnectionData ? <ClipLoader />
            : <p className="text-2xl font-bold pl-2">{connectionIssues}</p>}
        </div>
        <div className="shadow-sm rounded-lg p-5 border w-1/3 h-32 flex flex-col justify-between">
          <div className="flex gap-3 items-center">
            <img src="apiCalls.png" alt="total api calls" className="h-10 w-10" />
            <h2 className="font-medium text-gray-600"> Total API Calls</h2>
          </div>
          <p className="text-2xl font-bold pl-2">
            {loadingConnectionData ? <ClipLoader />
              : (adjustedConnectionData && adjustedConnectionData.map((connection) => {
                if (connection.times_used) {
                  return connection.times_used;
                }
                return 0;
              }).reduce((a, b) => a + b, 0)) || 0}
          </p>

        </div>
      </div>
      <div className="flex flex-col h-full overflow-auto max-h-full">
        <div className="flex justify-between items-center border-l border-r border-t p-4 rounded-t-lg">
          <div className="flex flex-col gap-1 ">
            <p className="text-gray-600 font-medium text-sm">Connected Warehouse Management Systems in your application</p>
          </div>
          <div className="flex gap-1">
            <input
              type="text"
              placeholder="Search"
              className="border rounded-lg px-3 py-1"
              value={quickFilterSearchValue}
              onChange={handleQuickFilterSearchChange}
            />
            <button
              type="submit"
              onClick={() => setSelectedGridFilter('OK')}
              className={`px-3 py-1 border
                ${selectedGridFilter === 'OK' ? 'bg-trackstarOKDark text-white border-trackstarOKDark' : 'border-trackstarOKLight text-trackstarOKDark hover:bg-green-200'}
                rounded-lg
                `}
            >
              OK
            </button>
            <button
              type="submit"
              onClick={() => setSelectedGridFilter('Errors')}
              className={`px-3 py-1 border
              ${selectedGridFilter === 'Errors' ? 'bg-trackstarBadDark text-white border-trackstarBadDark' : 'border-trackstarBadLight text-trackstarBadDark hover:bg-red-200'}
                rounded-lg `}
            >
              Errors
            </button>
            <button
              onClick={() => setSelectedGridFilter('All')}
              type="submit"
              className={`px-3 py-1 border
              ${selectedGridFilter === 'All' ? 'bg-trackstarAllDark text-white border-trackstarAllDark' : 'border-trackstarAllLight text-trackstarAllDark hover:bg-purple-200'}
                  rounded-lg`}
            >
              All
            </button>
          </div>
        </div>
        <DataGridPro
          rows={connectionRows || []}
          columns={colHeaders}
          pagination
          slots={{ columnMenu: CustomColumnMenu }}
          autoPageSize
          pageSizeOptions={[]}
          rowHeight={50}
          className="shadow-sm"
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#F5F5F5',
              borderRadius: '0px !important',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
              fontWeight: '400',
              fontSize: '13px',
              color: '#6b7280',
            },
            '& .column-header-no-border .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            borderRadius: '0px !important',
            '& .MuiDataGrid-cell': {
              fontSize: '13px',
              fontWeight: '400',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              transform: 'scale(1.01)',
            },
          }}
          loading={loadingConnectionData || !connectionRows}
          getDetailPanelHeight={getDetailPanelHeight}
          apiRef={gridApiRef}
          onRowClick={(params) => { openConnectionDetail(params.row); }}
        />
      </div>
      <GenerateMagicLinkModal
        isOpen={generateMagicLinkModalOpen}
        onClose={() => setGenerateMagicLinkModalOpen(false)}
        connectionRows={connectionRows}
      />

      <Tooltip anchorSelect="#created-date-tip" content="The date this connection was created." />
      <Tooltip anchorSelect="#last-used-tip" content="The last time the Trackstar API was called with this connection id." />
      <Tooltip anchorSelect="#latest-sync-tip" content="The most recent time Trackstar synced this connection's from the WMS." />
      <Tooltip anchorSelect="#customer-id-tip" content="Your customer identifier for this connection." />
      <Drawer anchor="right" open={showConnectionDetail} onClose={() => setShowConnectionDetail(false)} PaperProps={{ sx: { width: '50%' } }}>
        <ConnectionDetailDrawer
          showConnectionDetail={showConnectionDetail}
          connectionDetail={connectionDetail}
          fetchConnectionData={fetchConnectionData}
          closeDrawer={() => setShowConnectionDetail(false)}
        />
      </Drawer>

    </div>
  );
}

export default Connections;

import React, { useState, useEffect } from 'react';
import { useOrganization } from '@clerk/clerk-react';
import { LicenseInfo } from '@mui/x-license-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useFetch } from './api';
import DeleteObjectModal from './DeleteObjectModal';
import GenerateMagicLinkModal from './GenerateMagicLinkModal';

LicenseInfo.setLicenseKey(
  'cfb90503f538324ac26942148034f3d4Tz05NzAwOSxFPTE3NTY0MDY1NjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
);

function MagicLinks() {
  const { organization } = useOrganization();
  const [loadingMagicLinks, setLoadingMagicLinks] = useState(false);
  const [magicLinks, setMagicLinks] = useState([]);
  const [isDeletingMagicLink, setIsDeletingMagicLink] = useState(false);
  const [magicLinkCopied, setMagicLinkCopied] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showCreateConfirmation, setShowCreateConfirmation] = useState(false);
  const [generateMagicLinkModalOpen, setGenerateMagicLinkModalOpen] = useState(false);

  const trackstar = useFetch();

  const copyToClipboard = (content) => {
    const textArea = document.createElement('textarea');
    textArea.value = content;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setMagicLinkCopied(content);
    setTimeout(() => {
      setMagicLinkCopied('');
    }, 1500); // Reset the copied state after 1.5 seconds
  };

  useEffect(() => {
    if (organization) {
      setLoadingMagicLinks(true);
      trackstar('internal/link/magic-link').then((response) => {
        const data = response['magic-links'];
        setMagicLinks(data);
        setLoadingMagicLinks(false);
      });
    }
  }, []);

  const handleCloseDeleteModal = (cancelled) => {
    if (!cancelled) {
      setShowDeleteConfirmation(true);
    }
    setDeleteModalOpen(false);
    setRowToDelete(null);
  };

  const handleDeleteMagicLink = (magicLink) => {
    setIsDeletingMagicLink(true);
    trackstar(`internal/link/magic-link/${magicLink}`, 'DELETE').then(() => {
      setMagicLinks(
        (
          prevMagicLinks,
        ) => prevMagicLinks.filter((prevMagicLink) => prevMagicLink.id !== magicLink),
      );
      handleCloseDeleteModal(false);
      setIsDeletingMagicLink(false);
    });
  };

  const handleOpenDeleteModal = (row) => {
    setDeleteModalOpen(true);
    setRowToDelete(row);
  };

  const handleGenerateMagicLinkModalClose = (returnedMagicLink) => {
    setGenerateMagicLinkModalOpen(false);
    if (returnedMagicLink) {
      setLoadingMagicLinks(true);
      trackstar('internal/link/magic-link').then((response) => {
        const data = response['magic-links'];
        setMagicLinks(data);
        setLoadingMagicLinks(false);
      });
      setShowCreateConfirmation(true);
    }
  };

  function noMagicLinks() {
    return (
      <div className="flex justify-center items-center h-full m-6">
        <div className="flex flex-col justify-center items-center w-full p-10">
          <h1 className="text-4xl font-semibold leading-9">No Magic Links</h1>
          <p className="text-lg font-medium py-5">Create some now!</p>
        </div>
      </div>
    );
  }

  const columns = [{
    field: 'id',
    headerName: 'Id',
    flex: 2,
    renderCell: (params) => (
      <div className="flex items-center w-11/12 justify-between">
        <span>{params.value}</span>
        <button
          type="button"
          onClick={() => copyToClipboard(`https://link.trackstarhq.com/${params.row.id}`)}
          className="px-4 py-2 bg-trackstarAllDark text-white rounded-lg"
        // there's a few px overflorw to the right on some screens. do -2 to be safe
          style={{ position: 'sticky', right: -2, top: 0 }}
        >
          {magicLinkCopied === `https://link.trackstarhq.com/${params.row.id}` ? 'Copied!' : 'Copy URL'}
        </button>
      </div>
    ),
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    flex: 1,
    renderCell: (params) => (
      <span>{new Date(params.value).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' })}</span>
    ),
  },
  {
    field: 'expires_at',
    headerName: 'Expires At',
    flex: 1,
    renderCell: (params) => (
      // params.value is a utc epoch timestamp
      <span>{new Date(params.value * 1000).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' })}</span>
    ),
  },
  {
    field: 'customer_id',
    headerName: 'Customer ID',
    flex: 1,
    renderCell: (params) => (
      <span>{params.value}</span>
    ),
  },
  {
    field: 'integration_allow_list',
    headerName: 'Integration Allow List',
    flex: 1,
    renderCell: (params) => (
      <span>{params.value.join(', ')}</span>
    ),
  },
  {
    field: 'delete',
    width: 20,
    headerName: '',
    disableColumnMenu: true,
    filterable: false,
    resizable: false,
    sortable: false,
    headerClassName: 'column-header-no-border',
    renderCell: (params) => (
      <button
        type="button"
        onClick={() => handleOpenDeleteModal(params.row.id)}
        onKeyDown={() => handleOpenDeleteModal(params.row.id)}
      >
        <img src="/trash.png" alt="delete" className="h-5 w-5" />
      </button>
    ),
  }];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">Magic Links</h1>
        <button
          type="button"
          style={{
            border: '1px solid #2a6faf', borderRadius: '8px', color: '#2a6faf', background: null, padding: '10px 20px',
          }}
          className="hover:bg-trackstarBlueHover"
          onClick={() => setGenerateMagicLinkModalOpen(true)}
        >
          <div className="flex justify-between">
            <AutoFixHighIcon className="h-5 w-5 mr-2" />
            Generate Magic Link
          </div>
        </button>
      </div>
      {
        showCreateConfirmation && (
          <div className="flex items-center justify-center bg-green-100 px-4 py-3 rounded-lg">
            <p className="text-green-700 font-semibold">Magic Link successfully created!</p>
          </div>
        )
      }
      {
        showDeleteConfirmation && (
          <div className="flex items-center justify-center bg-green-100 px-4 py-3 rounded-lg">
            <p className="text-green-700 font-semibold">Magic Link successfully deleted!</p>
          </div>
        )
      }
      <DataGridPro
        rows={magicLinks}
        columns={columns}
        getRowId={(row) => row.id}
        pageSizeOptions={[]}
        loading={loadingMagicLinks}
        pagination
        rowHeight={50}
        autoHeight
        slots={
        {
          noRowsOverlay: noMagicLinks,
        }
      }
        className="shadow-sm"
        sortModel={[{ field: 'created_at', sort: 'desc' }]}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#F5F5F5',
            borderRadius: '0px !important',
            fontFamily: 'ui-sans-serif, system-ui, sans-serif',
            fontWeight: '400',
            fontSize: '13px',
            color: '#6b7280',
          },
          '& .column-header-no-border .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          borderRadius: '0px !important',
          '& .MuiDataGrid-cell': {
            fontSize: '13px',
            fontWeight: '400',
            fontFamily: 'ui-sans-serif, system-ui, sans-serif',
          },
        }}
      />
      <DeleteObjectModal
        isOpen={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteMagicLink}
        isLoading={isDeletingMagicLink}
        row={rowToDelete}
        objectType="Magic Link"
      />
      <GenerateMagicLinkModal
        isOpen={generateMagicLinkModalOpen}
        onClose={handleGenerateMagicLinkModalClose}
      />
    </div>
  );
}

export default MagicLinks;

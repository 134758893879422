import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Typography from '@mui/material/Typography';
import ClipLoader from 'react-spinners/ClipLoader';
import HorizontalScrollText from './HorizontalScroll';
import { useFetch } from './api';

function GenerateMagicLinkModal({ isOpen, onClose, connectionRows }) {
  const [linkDuration, setLinkDuration] = useState(7);
  const [connectionId, setConnectionId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [endpointListLoading, setEndpointListLoading] = useState(false);
  const [endpointList, setEndpointList] = useState([]);
  const [selectedEndpointList, setSelectedEndpointList] = useState([]);
  const [integrationListLoading, setIntegrationListLoading] = useState(false);
  const [integrationList, setIntegrationList] = useState([]);
  const [selectedAllowList, setSelectedAllowList] = useState([]);
  const [selectedBlockList, setSelectedBlockList] = useState([]);
  const [magicLinkLoading, setMagicLinkLoading] = useState(false);
  const [magicLink, setMagicLink] = useState('');

  const connectionIds = {};
  if (connectionRows) {
    connectionRows.forEach((row) => {
      connectionIds[row.connection_id] = row.customer_id;
    });
  }

  const trackstar = useFetch();

  useEffect(() => {
    setEndpointListLoading(true);
    trackstar('internal/available-actions').then((data) => {
      setEndpointList(data.actions);
      setEndpointListLoading(false);
    });
  }, []);

  useEffect(() => {
    setIntegrationListLoading(true);
    const url = 'internal/link/get-available-integrations';
    const body = {
      endpoints: selectedEndpointList,
      integration_allow_list: selectedAllowList,
      integration_block_list: selectedBlockList,
      integration_type: 'wms',
    };
    const buildIntegrationList = [];
    trackstar(url, 'POST', body).then((data) => {
      setIntegrationListLoading(false);
      Object.keys(data).forEach((integration) => {
        buildIntegrationList.push(integration);
      });
      buildIntegrationList.sort();
      setIntegrationList(buildIntegrationList);
    });
  }, [selectedEndpointList]);

  useEffect(() => {
    // remove selected endpoints that are no longer available
    const buildAllowList = [];
    const buildBlockList = [];
    selectedAllowList.forEach((integration) => {
      if (integrationList.includes(integration)) {
        buildAllowList.push(integration);
      }
    });
    selectedBlockList.forEach((integration) => {
      if (integrationList.includes(integration)) {
        buildBlockList.push(integration);
      }
    });
    setSelectedAllowList(buildAllowList);
    setSelectedBlockList(buildBlockList);
  }, [integrationList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMagicLinkLoading(true);
    const args = {
      link_duration: linkDuration,
      connection_id: connectionId || null,
      customer_id: customerId || null,
      integration_allow_list: selectedAllowList,
      integration_block_list: selectedBlockList,
      integrations_with_endpoints: selectedEndpointList,
    };
    trackstar('internal/link/magic-link', 'POST', args).then((data) => {
      const fullMagicLink = `https://link.trackstarhq.com/${data['magic-link']}`;
      setMagicLink(fullMagicLink);
      setMagicLinkLoading(false);
    });
  };

  const getIntegrationsPlaceholder = (selectedOptions, exclusiveWithName, exclusiveWithList) => {
    let placeholder = '';
    if (integrationListLoading) {
      placeholder = 'Loading...';
    } else if (connectionId) {
      placeholder = 'Exclusive with Connection ID';
    } else if (exclusiveWithList.length > 0) {
      placeholder = `Exclusive with ${exclusiveWithName}`;
    } else if (selectedOptions.length === 0) {
      placeholder = 'Select integrations...';
    }
    return placeholder;
  };

  const getEndpointsPlaceholder = () => {
    let placeholder = '';
    if (endpointListLoading) {
      placeholder = 'Loading...';
    } else if (connectionId) {
      placeholder = 'Exclusive with Connection ID';
    } else if (selectedEndpointList.length === 0) {
      placeholder = 'Select endpoints...';
    }
    return placeholder;
  };

  if (!isOpen) {
    return null;
  }

  const magicLinkDescription = 'Send a secure URL to an end user that opens the Trackstar install flow.';
  const linkDurationDescription = 'The number of days the magic link will be valid for.';
  const connectionIdDescription = 'Only use if you want to reinstall a certain connection ID. Exclusive with all other fields.';
  const customerIdDescription = 'An identifier for the customer using this magic link. Used for tracking purposes only.';
  const endpointListDescription = 'Only show integrations that have the selected endpoints supported.';
  const allowListDescription = 'Only show integrations in the selected list.';
  const blockListDescription = 'Do not show integrations in the selected list.';

  const additionalLinkSettingsRenderInput = (params, placeholder) => {
    const {
      id,
      fullWidth,
      disabled,
      size,
      InputProps,
      inputProps,
    } = params;
    return (
      <TextField
        id={id}
        fullWidth={fullWidth}
        disabled={disabled}
        size={size}
        inputProps={inputProps}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={InputProps}
        variant="outlined"
        placeholder={placeholder}
      />
    );
  };

  const linkSettings = (
    <>
      <div className="flex flex-col gap-2 mt-4">
        <span htmlFor="endpointList" className="text-sm font-semibold">Endpoint List</span>
        <label htmlFor="endpointList" className="text-xs text-gray-500 pt-0">{endpointListDescription}</label>
        <Autocomplete
          multiple
          options={endpointList}
          value={selectedEndpointList}
          onChange={(_, newValue) => setSelectedEndpointList(newValue)}
          renderInput={(params) => (
            additionalLinkSettingsRenderInput(params, getEndpointsPlaceholder())
          )}
          disabled={endpointListLoading || connectionId}
          size="small"
        />
      </div>
      <div className="flex flex-col gap-2 mt-4">
        <span htmlFor="allowList" className="text-sm font-semibold">Allow List</span>
        <label htmlFor="allowList" className="text-xs text-gray-500 pt-0">{allowListDescription}</label>
        <Autocomplete
          multiple
          id="allowList"
          options={integrationList}
          value={selectedAllowList}
          onChange={(_, newValue) => setSelectedAllowList(newValue)}
          renderInput={(params) => (additionalLinkSettingsRenderInput(params, getIntegrationsPlaceholder(selectedAllowList, 'Block List', selectedBlockList)))}
          disabled={integrationListLoading || selectedBlockList.length > 0 || connectionId}
          size="small"
        />
      </div>
      <div className="flex flex-col gap-2 mt-4">
        <span htmlFor="blockList" className="text-sm font-semibold">Block List</span>
        <label htmlFor="blockList" className="text-xs text-gray-500 pt-0">{blockListDescription}</label>
        <Autocomplete
          multiple
          id="blockList"
          options={integrationList}
          value={selectedBlockList}
          onChange={(_, newValue) => setSelectedBlockList(newValue)}
          renderInput={(params) => (additionalLinkSettingsRenderInput(params, getIntegrationsPlaceholder(selectedBlockList, 'Allow List', selectedAllowList)))}
          disabled={integrationListLoading || selectedAllowList.length > 0 || connectionId}
          size="small"
        />
      </div>
    </>
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={() => onClose(magicLink)} tabIndex={-1} aria-hidden="true" />
      <div className="bg-white rounded-lg p-6 max-h-[80vh] w-96 relative z-10 overflow-y-auto">
        <h2 className="text-2xl font-semibold pt-1 pb-0">Generate Magic Link</h2>
        <span className="text-xs">{magicLinkDescription}</span>
        <div className="flex flex-col gap-2 mt-0">
          <span htmlFor="linkDuration" className="text-sm font-semibold">Link Duration (Days)</span>
          <label htmlFor="linkDuration" className="text-xs text-gray-500 pt-0">{linkDurationDescription}</label>
          <input
            type="number"
            id="linkDuration"
            name="linkDuration"
            value={linkDuration}
            onChange={(e) => setLinkDuration(e.target.value)}
            className="border border-gray-300 rounded-md p-2"
            size="small"
            required
          />
          <span className="text-xs text-red-500">{linkDuration ? '' : 'Link duration is required.'}</span>
        </div>
        <div className="flex flex-col gap-2 mt-0">
          <span htmlFor="customerId" className="text-sm font-semibold">Customer ID (optional)</span>
          <label htmlFor="customerId" className="text-xs text-gray-500 pt-0">{customerIdDescription}</label>
          <input
            type="text"
            id="customerId"
            name="customerId"
            value={customerId}
            onChange={(e) => setCustomerId(e.target.value)}
            className="border border-gray-300 rounded-md p-2"
            size="small"
            disabled={connectionId}
            required
          />
        </div>
        <div className="flex flex-col gap-2 mt-2">
          <span htmlFor="connectionId" className="text-sm font-semibold">Reinstall Connection ID (optional)</span>
          <label htmlFor="connectionId" className="text-xs text-gray-500 pt-0">{connectionIdDescription}</label>
          <Autocomplete
            options={Object.keys(connectionIds)}
            value={connectionId}
            onChange={(_, newValue) => {
              setConnectionId(newValue);
              setSelectedEndpointList([]);
              setSelectedAllowList([]);
              setSelectedBlockList([]);
              setCustomerId(connectionIds[newValue]);
            }}
            renderInput={(params) => (
              additionalLinkSettingsRenderInput(params, null)
            )}
            size="small"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <Accordion sx={{ boxShadow: 'none' }} disableGutters>
            <AccordionSummary
              id="link-settings-header"
              expandIcon={<ArrowForwardIosSharpIcon style={{ fontSize: 12 }} />}
              aria-controls="link-settings-content"
              sx={{
                flexDirection: 'row-reverse',
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotate(90deg)' },
                paddingLeft: 0,
              }}
            >
              <Typography variant="body2" className="pl-1">Additional Link Settings</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ marginTop: 0, paddingTop: 0 }}>
              {linkSettings}
            </AccordionDetails>
          </Accordion>
          <div className="flex justify-end pt-4">
            <button
              type="button"
              onClick={() => onClose(magicLink)}
              className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
            >
              {magicLink ? 'Close' : 'Cancel'}
            </button>
            <button
              type="submit"
              className="bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
              disabled={magicLinkLoading}
            >
              {magicLinkLoading ? <ClipLoader size={18} color="#fff" /> : 'Generate'}
            </button>
          </div>
        </form>
        {magicLink && (
          <div className="mt-4">
            <p className="text-green-700 font-semibold">
              Magic Link successfully created!
            </p>
            <p className="text-sm text-gray-500">
              Share the link below with the end user:
            </p>
            <div className="mt-4 p-2 border border-gray-300 rounded-md flex items-center">
              <HorizontalScrollText content={magicLink} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default GenerateMagicLinkModal;

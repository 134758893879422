import React, { useState, useEffect } from 'react';
import { useOrganization, useAuth } from '@clerk/clerk-react';
import { LicenseInfo } from '@mui/x-license-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';
import DeleteObjectModal from './DeleteObjectModal';
import InviteUserModal from './InviteUserModal';

LicenseInfo.setLicenseKey(
  'cfb90503f538324ac26942148034f3d4Tz05NzAwOSxFPTE3NTY0MDY1NjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
);

function Users() {
  const { organization } = useOrganization();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const { userId, orgRole } = useAuth();
  const [allRows, setAllRows] = useState([]);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);
  const [inviteToRevoke, setInviteToRevoke] = useState(null);
  const [objectTypeToRevoke, setObjectTypeToRevoke] = useState(null);

  const hasInvitationPermission = orgRole === 'admin';

  const getUsers = () => {
    if (!organization) return;
    setAllRows([]);
    organization.getMemberships().then((memberships) => {
      memberships.forEach((membership) => {
        const { firstName, lastName } = membership.publicUserData;
        let name = 'Unknown';
        if (firstName && lastName) {
          name = `${firstName} ${lastName}`;
        } else if (firstName) {
          name = firstName;
        } else if (lastName) {
          name = lastName;
        }
        const membershipWithFullName = { ...membership, emailAddress: name };
        setAllRows((prev) => [...prev, membershipWithFullName]);
      });
    });
    if (hasInvitationPermission) {
      organization.getInvitations().then((invites) => {
        invites.data.forEach((invite) => {
          if (invite.status === 'pending') {
            setAllRows((prev) => [...prev, invite]);
          }
        });
      });
    }
  };

  useEffect(() => {
    setLoadingUsers(true);
    getUsers();
    setLoadingUsers(false);
  }, []);

  const handleOpenInviteUserModal = () => {
    setIsInviteUserModalOpen(true);
  };

  const handleCloseInviteUserModal = () => {
    setIsInviteUserModalOpen(false);
  };

  const invitationRevokeFunction = (r) => {
    r.revoke().then(() => {
      getUsers();
      setIsRevokeModalOpen(false);
    });
  };
  const memberRevokeFunction = (r) => {
    r.destroy().then(() => {
      getUsers();
      setIsRevokeModalOpen(false);
    });
  };

  const handleOpenRevokeModal = (row) => {
    setIsRevokeModalOpen(true);
    setInviteToRevoke(row);
    // pending invite
    if (row.status === 'pending') {
      setObjectTypeToRevoke('invitation');
    } else {
      // full member
      setObjectTypeToRevoke('user');
    }
  };
  const revokeFunction = objectTypeToRevoke === 'invitation' ? invitationRevokeFunction : memberRevokeFunction;

  const revokeDisabled = (row) => {
    // can't do if not admin
    if (orgRole !== 'admin') {
      return true;
    }
    // can't revoke own membership
    if (row.publicUserData?.userId === userId) {
      return true;
    }
    return false;
  };

  const handleCloseRevokeModal = () => {
    setIsRevokeModalOpen(false);
  };

  const sendInvite = (email, role) => {
    organization.inviteMember({ emailAddress: email, role }).then(() => {
      getUsers();
    });
  };

  const columns = [{
    field: 'emailAddress',
    headerName: 'User',
    flex: 1,
    renderCell: (params) => (
      <div className="flex items-center w-3/4 justify-between">
        <span>{params.value}</span>
      </div>
    ),
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    renderCell: (params) => {
      const words = params.value.split('_');
      const role = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      return (
        <div className="flex items-center w-3/4 justify-between">
          <span>{role}</span>
        </div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: (params) => {
      let value = '';
      if (params.value) {
        value = params.value;
      } else {
        value = 'joined';
      }
      return (
        <div className="flex items-center w-3/4 justify-between">
          <span>{value.charAt(0).toUpperCase() + value.slice(1)}</span>
        </div>
      );
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Invitation Sent/Accepted',
    flex: 1,
    renderCell: (params) => (
      <span>{new Date(params.value).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' })}</span>
    ),
  },
  {
    field: 'revoke',
    width: 20,
    headerName: '',
    disableColumnMenu: true,
    filterable: false,
    resizable: false,
    sortable: false,
    headerClassName: 'column-header-no-border',
    renderCell: (params) => (
      <button
        type="button"
        disabled={revokeDisabled(params.row)}
        onClick={() => handleOpenRevokeModal(params.row)}
      >
        {revokeDisabled(params.row) ? null : (
          <img src="/trash.png" alt="delete" className="h-5 w-5" />
        )}
      </button>
    ),
  }];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">Users</h1>
        {hasInvitationPermission && (
          <button
            type="button"
            onClick={handleOpenInviteUserModal}
            className="bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
          >
            Invite User
          </button>
        )}
      </div>
      {hasInvitationPermission
        ? (
          <DataGridPro
            rows={allRows}
            columns={columns}
            pageSizeOptions={[]}
            pagination
            autoHeight
            loading={loadingUsers || allRows.length === 0}
            rowHeight={50}
            className="shadow-sm"
            sortModel={[{ field: 'created_at', sort: 'desc' }]}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#F5F5F5',
                borderRadius: '0px !important',
                fontFamily: 'ui-sans-serif, system-ui, sans-serif',
                fontWeight: '400',
                fontSize: '13px',
                color: '#6b7280',
              },
              '& .column-header-no-border .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              borderRadius: '0px !important',
              '& .MuiDataGrid-cell': {
                fontSize: '13px',
                fontWeight: '400',
                fontFamily: 'ui-sans-serif, system-ui, sans-serif',
              },
            }}
          />
        )
        : <p className="text-gray-600 font-semibold">Only admins can manage users</p> }
      <DeleteObjectModal
        isOpen={isRevokeModalOpen}
        onClose={handleCloseRevokeModal}
        onDelete={(row) => { revokeFunction(row); }}
        isLoading={false}
        row={inviteToRevoke}
        objectType={objectTypeToRevoke}
      />
      <InviteUserModal
        isOpen={isInviteUserModalOpen}
        onClose={handleCloseInviteUserModal}
        sendInvite={sendInvite}
      />
    </div>
  );
}

export default Users;
